
import { Config3D } from "@bright-spaces/engine-3d/dist/helpers/Config3D";
import Constants, { LOAD_TYPE } from "@bright-spaces/engine-3d/dist/helpers/Constants"
import { CurrentView } from '~/store/building/-constants'
import { CLIENT_CONFIG_3D, CLIENTS, ClientUtils } from "~/helpers/ClientUtils";
import OrganismPins from "~/components/organisms/project/OrganismPins.vue";

export default {
  name: 'OrganismBuilding',
  components: {OrganismPins},
  props: {
    customModel: {
      required: false,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      pinData: []
    }
  },
  computed: {
    isInitialized() {
      return this.$store.state.building.isInitialized
    },
    cdnBase() {
      return this.$store.getters.cdnBase
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    isFloorView() {
      return this.$store.state.building.currentView === CurrentView.FLOOR
    },
    spaces() {
      return this.$store.state.project.project.spaces
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    floors() {
      return this.$store.state.project.project.floors
    },
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    pinConfigBuilding() {
      let pins = [];
      if (this.$store.getters.getActiveProjectSettings.building.pinsData) {
        pins = this.$store.getters.getActiveProjectSettings.building.pinsData
      }
      return pins;
    },
    pinConfigFloor() {
      let pins = [];
      if (this.$store.getters.getActiveProjectSettings.floor.pinsData) {
        pins = this.$store.getters.getActiveProjectSettings.floor.pinsData
      }
      return pins;
    },
    pinConfig() {
      return this.isFloorView ? this.pinConfigFloor : this.pinConfigBuilding;
    },
    htmlPinsEnabled() {
      return this.isFloorView ? this.$store.getters.getActiveProjectSettings.floor.htmlPins || false : this.$store.getters.getActiveProjectSettings.building.htmlPins || false;
    },
    hasPins() {
      if (!this.htmlPinsEnabled) return false;
      return this.pinConfig && this.pinConfig.pins && this.pinConfig.pins.length > 0
    },
    defaultFitoutActive() {
      return this.$store.getters.getActiveProjectSettings.floor.activeFitout
    }
  },
  watch: {
    isInitialized: function (value) {
      if (value) {
        window.addEventListener('resize', this.resizeListener)
        if (this.$route.query.v === 'debug') {
          const clientManager = this.engine3d.default();
          clientManager.sceneManager.debugLayerShow();
        }
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch('building/destroy')
    this.engine3d && this.engine3d.default().destroy();
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    initializeBuilding3D() {
      const payload = {
        pinsData: this.$store.getters.getActiveProjectSettings.building.pinsData,
        client: this.$config.CLIENT,
        project: this.$store.state.project.project,
        cdnBase: this.cdnBase,
        activeProject: this.activeProject,
        settings: this.$store.getters.getActiveProjectSettings
      }
      this.$store.dispatch('building/destroy')
      this.$store.dispatch('building/initBuilding', payload)
      // Init scene
      const defaultConfig3D = CLIENT_CONFIG_3D[this.$config.CLIENT].building;
      const modelPath = ClientUtils.getModelLoadingPath(this.$config.CLIENT, this.$store.getters.getActiveProjectData.slug, '/project');

      const payload3D = {
        cdnBase: this.$store.getters.cdnBase,
        activeProject: modelPath,
        hdrTexture: this.$store.getters.getActiveProjectSettings.building.hdrTexture,
        alpha: this.$store.getters.getActiveProjectSettings.building.alpha,
        beta: this.$store.getters.getActiveProjectSettings.building.beta,
        radius: this.$store.getters.getActiveProjectSettings.building.radius,
        upperBetaLimit: Constants.UPPER_BETA_LIMIT,
        target: {
          x: this.$store.getters.getActiveProjectSettings.building.target.x,
          y: this.$store.getters.getActiveProjectSettings.building.target.y,
          z: this.$store.getters.getActiveProjectSettings.building.target.z
        },
        target3D: {
          x: this.$store.getters.getActiveProjectSettings.building.target.x,
          y: this.$store.getters.getActiveProjectSettings.building.target.y,
          z: this.$store.getters.getActiveProjectSettings.building.target.z
        },
        pinsData: this.pinConfigBuilding.pins,
        highlightZoom: this.$store.getters.getActiveProjectSettings.building.highlightZoom,
        highlightValues: this.$store.getters.getActiveProjectSettings.building.highlightValues,
        htmlPins: this.$store.getters.getActiveProjectSettings.building.htmlPins || false,
        sceneTimeoutSeconds: this.$store.getters.getActiveProjectSettings.building.sceneTimeoutSeconds || 120,
        minZ: this.$store.getters.getActiveProjectSettings.building.minZ || Constants.CAMERA_3D_VIEW_MINZ,
      }
      const clientManager = this.engine3d.default();
      clientManager.onSceneReady = () => {
        this.$store.commit('building/setLoadingAssetsStatus', true);
        this.$store.commit('building/setLoadingScreenStatus', false);
        if (clientManager.enabledFeatures.pinManager) {
          setTimeout(() => {
            if (this.htmlPinsEnabled) {
              clientManager.enabledFeatures.pinManager.addPinPositionEventHTML();
              clientManager.enabledFeatures.pinManager.addPinVisibilityEventHTML();
            } else {
              clientManager.enabledFeatures.pinManager.setPinVisibilityOnCurrentOcclusion();
            }
          }, 100);
        }

        const manager = this.engine3d.getClientManager()
        if (manager) {
          if (window.innerWidth < 1200) {
            manager.showPinsByCategory('none');
          } else {
            manager.showPinsByCategory('');
          }
        }
      }
      clientManager.onAssetLoadingError = (message) => {
        this.$store.commit('building/setAssetLoadingError', message);
      }
      clientManager.setSceneNodeNames = () => {
        const buildingNames = []
        for (const building of this.$store.state.project.project.buildings) {
          buildingNames.push(building.code.toLowerCase())
        }
        clientManager.sceneNodeNames.buildingCodeNames = buildingNames;
        clientManager.sceneNodeNames.pinContentManagerUrl = payload3D.cdnBase
      }
      clientManager.init(payload3D, this.$refs.canvas, defaultConfig3D)
      clientManager.load(LOAD_TYPE.BUILDING);
    },
    initializeFloor3D(spaceId) {
      const space = this.spaces.find((s) => s.id === spaceId)
      if (!space) return
      const building = this.buildings.find((b) => b.id === space.building_id)
      const floor = this.floors.find((f) => f.id === space.floor_id)
      const payload = {
        pinsData: this.$store.getters.getActiveProjectSettings.floor.pinsData,
        space: space.space_code,
        building: building.code.toLowerCase(),
        floor: floor.code,
        floorType: floor.floor_type.model,
        spaceData: space,
        client: this.$config.CLIENT,
        project: this.$store.state.project.project,
        cdnBase: this.cdnBase,
        activeProject: this.activeProject,
        settings: this.$store.getters.getActiveProjectSettings,
      }
      /// Client Utils
      const skyboxSuffix = ClientUtils.getSkyboxPath(this.$config.CLIENT, {
        buildingCode: building.code.toLowerCase(),
        floor: floor.name
      })
      this.$store.dispatch('building/destroy')
      this.$store.dispatch('building/viewSpace', payload)
      this.$store.dispatch('building/initFloor', payload)
      // Init scene
      const modelPath = `objects/${this.$config.CLIENT}/${this.$store.getters.getActiveProjectData.slug}`;
      let imageProcessing = {
        toneMapping: 0,
        contrast: 1.0,
        exposure: 1.0,
        iblIntensity: 1.0,
      };
      if (this.$store.getters.getActiveProjectSettings.floor.imageProcessing) {
        imageProcessing = this.$store.getters.getActiveProjectSettings.floor.imageProcessing;
      }
      const payload3D = {
        cdnBase: this.$store.getters.cdnBase,
        activeProject: modelPath,
        hdrTexture: this.$store.getters.getActiveProjectSettings.floor.hdrTexture,
        skyboxTexture: this.$store.getters.getActiveProjectSettings.floor.skyboxTexture + skyboxSuffix,
        alpha: this.$store.getters.getActiveProjectSettings.floor.alpha,
        beta: this.$store.getters.getActiveProjectSettings.floor.beta,
        radius: this.$store.getters.getActiveProjectSettings.floor.radius,
        zoom3D: this.$store.getters.getActiveProjectSettings.floor.zoom3D || 1,
        upperBetaLimit: Constants.UPPER_BETA_LIMIT,
        highlightValues: [],
        floorCode: floor.floor_type.model.split(".gltf")[0].toLowerCase(),
        variant: ".gltf",
        target: {
          x: space.focus_target_x,
          y: space.focus_target_y,
          z: space.focus_target_z
        },
        startPosition: {
          x: space.camera_position_x,
          y: space.camera_position_y,
          z: space.camera_position_z
        },
        pinsData: this.pinConfigFloor.pins,
        imageProcessing,
        htmlPins: this.$store.getters.getActiveProjectSettings.floor.htmlPins || false,
        sceneTimeoutSeconds: this.$store.getters.getActiveProjectSettings.building.sceneTimeoutSeconds || 120,
      }
      const defaultConfig3D = CLIENT_CONFIG_3D[this.$config.CLIENT].floor;
      const clientManager = this.engine3d.default();
      clientManager.onSceneReady = () => {
        /// Hide measurements node
        if (this.$config.CLIENT === CLIENTS.SKANSKA) {
          clientManager.sceneManager.scene.getNodeByName("measurement_e2_floor2_11")?.setEnabled(false);
        }
        /// Locked node visibility
        if (
          this.$config.CLIENT === CLIENTS.RIVER ||
          this.$config.CLIENT === CLIENTS.SKANSKA_FINLAND ||
          this.$config.CLIENT === CLIENTS.ADVENTUM ||
          this.$config.CLIENT === CLIENTS.REVETAS
        ) {
          const allLockedNodes = clientManager.sceneManager.scene.getNodeByName("locked").getChildMeshes();
          const visibleLockedMesh = "locked_" + payload3D.floorCode + "_" + space.space_code;
          ClientUtils.setLockedNodesVisibility(allLockedNodes, visibleLockedMesh);
        }
        if (defaultConfig3D.includes(Config3D.fitoutsManager)) {
          const fitoutNode = clientManager.sceneManager.scene.getNodeByName(clientManager.sceneNodeNames?.fitouts);
          const fitoutsInfo = this.$store.getters.getActiveProjectSettings.floor.fitoutsInfo;
          this.$store.commit('building/setFitoutData', {
            initialized: true,
            data: ClientUtils.getFitoutNames(fitoutNode, fitoutsInfo) // this method is used to populate the store fitoutData parameter with an array of objects of type {text, value}
          })
        }

        if (!this.defaultFitoutActive) {
           setTimeout(() => {
            clientManager.changeFitout("none");
          }, 1)
        }

        /// Scene is ready to be displayed
        this.$store.commit('building/setLoadingAssetsStatus', true);
        if (clientManager.enabledFeatures.pinManager) {
          setTimeout(() => {
            if (this.htmlPinsEnabled) {
              clientManager.enabledFeatures.pinManager.addPinPositionEventHTML();
              clientManager.enabledFeatures.pinManager.addPinVisibilityEventHTML();
            } else {
              clientManager.enabledFeatures.pinManager.setPinVisibilityOnCurrentOcclusion();
            }
          }, 100);
        }
      }
      clientManager.onAssetLoadingError = (message) => {
        this.$store.commit('building/setAssetLoadingError', message);
      }
      clientManager.setSceneNodeNames = () => {
        clientManager.sceneNodeNames.fitouts = "fitouts_" + payload3D.floorCode + "_" + space.space_code.replace(/;/g, '_');
        /// Set camera focus node
        if (this.$config.CLIENT === CLIENTS.RIVER || this.$config.CLIENT === CLIENTS.SKANSKA_FINLAND || this.$config.CLIENT === CLIENTS.ADVENTUM) {
          clientManager.sceneNodeNames.cameraFocusNode = ClientUtils.getLockedFocusNode(space.space_code, payload3D.floorCode);
          clientManager.sceneNodeNames.floorLockedNode = "locked";
        }
        else {
          clientManager.sceneNodeNames.cameraFocusNode = clientManager.sceneNodeNames.fitouts;
          clientManager.sceneNodeNames.floorLockedNode = "";
        }

        const spaces = ClientUtils.getSpaces(this.$config.CLIENT, {
          spaceCode: space.space_code,
          floorCode: payload3D.floorCode
        })
        clientManager.sceneNodeNames.selectedSpaces = spaces.selectedSpaces;
        clientManager.sceneNodeNames.selectedSpaceCodes = spaces.selectedSpaceCodes;
        clientManager.sceneNodeNames.separatorNode = ClientUtils.getSeparatorsNodeName(this.$config.CLIENT, {
          floorCode: payload3D.floorCode
        });

      }
      clientManager.init(payload3D, this.$refs.canvas, defaultConfig3D)
      clientManager.load(LOAD_TYPE.FLOOR);
    },
    resizeListener() {
      this.windowHeight = window.innerHeight
      this.$nextTick(() => {
        const clientManager = this.engine3d.default();
        if (!clientManager) return;
        clientManager.resize();
      })
    }
  }
}
